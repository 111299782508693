<template>
<b-sidebar
    id="add-new-package-sidebar"
    :visible="isAddNewPackageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="$emit('clicked',false)"
    @change="(val) => $emit('one')">

    <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
                Add New Package 
            </h5>

            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>

        </div>
        <b-modal id="modal-xl" ref="my-modal" ok-only no-close-on-backdrop ok-title="Close" scrollable size="xl" title="Invoice Review">
          <iframe :src="iframe_src" style="border:0px"  width="100%" height="600px" type="application/pdf" />    
        </b-modal>
            <!-- <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-xl variant="outline-primary" > Scrolling Content inside Modal </b-button>-->
            <!-- BODY -->
            <validation-observer ref="simpleRules">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <label class="section-label mb-1">Package Details</label>
                    <validation-provider #default="validationContext" name="Package Name" rules="required">
                        <b-form-group label="Package" label-for="package" :state="getValidationState(validationContext)">
                            <v-select v-model="selected_package" :options="packageOptions" :clearable="false" input-id="package"/>
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="Expire Date" rules="required">

                        <b-form-group label="Expire Date" label-for="expire-date" :state="getValidationState(validationContext)">
                            <flat-pickr v-model="expire_at" class="form-control" :config="{ dateFormat: 'Y-m-d'}"/>
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="Price">
                        <b-form-group label="Price" label-for="Price">
                            <b-form-input id="price" v-model="now_price" :readonly="lock_price" :state="getValidationState(validationContext)" trim/>

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="quantity">
                        <b-form-group label="Quantity" label-for="Quantity">
                            <b-form-input id="quantity" v-model="now_quantity" :readonly="lock_quantity" :state="getValidationState(validationContext)" trim/>

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider #default="validationContext" name="Price">
                        <b-form-group label="Remarks" label-for="remarks">
                            <b-form-textarea id="remarks" v-model="packageData.remarks" trim/>

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <hr class="my-2">
                        <label class="section-label mb-1">Payment Details</label>

                        <validation-provider #default="validationContext" name="Payment Method" rules="">
                            <b-form-group label="Payment Method" label-for="payment-method" :state="getValidationState(validationContext)">
                                <v-select v-model="packageData.payment_method" :reduce="val => val.value" :options="paymentMethods" :clearable="false" input-id="paymentMethod"/>
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider #default="validationContext" rules="required|between:0,100000" name="Payment Amount">
                            <b-form-group label="Payment Amount" label-for="payment_amount">
                                <b-form-input id="payment_amount" v-model="packageData.payment_amount" :state="getValidationState(validationContext)" trim/>

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                    </validation-provider>

                    <div v-if="packageData.payment_method == 'STRIPE'">
                        <hr class="mt-3"/>
                        <validation-provider #default="validationContext" name="Card Number">
                            <b-form-group label="Credit Card Number" label-for="card">
                                <b-form-input id="card" v-mask="credit_card_format" v-model="card.number" :state="getValidationState(validationContext)" trim/>

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider #default="validationContext" name="Expire">
                            <b-form-group label="Expiry Date (YYYY-MM)" label-for="Expiry Date">
                                <b-form-input id="expire" v-mask="'####-##'" v-model="card.expire" :state="getValidationState(validationContext)" trim/>

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider #default="validationContext" rules="required|between:0,999" name="CVC">
                            <b-form-group label="CVC" label-for="cvc">
                                <b-form-input id="cvc" v-mask="cvc_format" v-model="card.cvc" :state="getValidationState(validationContext)" trim/>

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <b-form-checkbox checked="false" name="check-button" class="my-1 custom-control-danger" switch inline v-model="installment.enabled">
                            分期付款
                        </b-form-checkbox>

                        <div ref="installment_detail" v-if="installment.enabled === true">

                            <label for="demo-sb">分期次數</label>
                            <b-form-spinbutton id="demo-sb" v-model="installment.quantity" min="2" max="24" class="mb-1"/>
                            <b-form-group label="每月金額" label-for="">
                                <b-form-input id="expire" v-model="installment_amount" readonly/>
                            </b-form-group>

                        </div>
                    </div>
                    <hr class="my-2"/>
                    <!-- Form Actions -->
                    <div v-for="(payment, index) in payment_method">
                        {{payment.method}}
                        - $
                        {{payment.amount}}
                    </div>
                    <h4>Total :
                        {{total}}</h4>
                    <div class="mt-2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="button" block @click="add_payment()" :disabled="show">
                            Add Payment
                        </b-button>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="button" variant="outline-warning" block @click="clear_payment()">
                            Clear Payment
                        </b-button>

                        <!-- <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="$router.push(`/epad/6/`)" > GO </b-button> -->

                    </div>
                    <div v-if="packageData.package.has_commission == 'Y'">
                        <hr class="my-2">
                            <label class="section-label mb-1">Commission Details</label>
                              <b-row>
                                <b-col cols="7">
                                  <v-select v-model="selected_staff" :options="staffs" class="payment-selector" :clearable="false"/>

                                  <b-form-input v-mask="'###'" v-model="percentage" trim class="mt-1"/>
                                  <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" size="sm" class="mt-1" block @click="add_commission()" variant="primary">
                                      <font-awesome-icon icon="fa-solid fa-plus" class="mr-1"/>
                                      Add Staff
                                  </b-button>
                                  <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" size="sm" class="mt-1" block @click="clear_commission()" variant="outline-danger">
                                      <font-awesome-icon icon="fa-solid fa-times" class="mr-1"/>
                                      Clear
                                  </b-button>
                                </b-col>
                                <b-col cols="5">
                                  <div v-for="(record, index) in commission">
                                      {{record.user.label}}-{{record.percentage}}%
                                  </div>
                                </b-col>
                              </b-row>
                        <hr/>

                      </div>
                        <hr/>

                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block class="mt-2" type="button" :disabled="processing" @click="submit_now()">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="button" block class="mb-4" variant="outline-secondary" @click="hide">
                            Cancel
                        </b-button>
                    </b-form>
                </validation-observer>
            </template>

        </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BRow, BCol, BFormGroup, BFormInput, BOverlay, BFormInvalidFeedback, BButton, BModal, VBModal, BFormTextarea, BFormCheckbox, BFormSpinbutton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import countries from '@/@fake-db/data/other/countries'
import router from '@/router'
export default {
  components: {
    BRow,
    BCol,
    ToastificationContent,
    flatPickr,
    BSidebar,
    BForm,
    BFormGroup,
    BFormSpinbutton,
    BFormCheckbox,
    BFormInput,
    BOverlay,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BModal, VBModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: 'isAddNewMemberSidebarActive',
    event: 'update:is-add-new-Member-sidebar-active',
  },
  props: {
    paymentMethods: {
      type: Array,
    },
    isAddNewPackageSidebarActive: {
      type: Boolean,
      required: true,
    },
    packageOptions : {
      type: Array,
      required: true,
    },
    userData : {
      type: Object
    }
  },
  computed: {
    installment_amount() {
      let total = 0
      total = this.packageData.payment_amount / this.installment.quantity
    //  total = Math.ceil(total)
      total = (Math.round(total * 100) / 100).toFixed(2)
      return total
    },
    total() {
      let total = 0
      this.payment_method.forEach((item, i) => {
        total = total + parseInt(item.amount)
      });
      return total
    },
    default_expiry_date() {
      try {
      var myCurrentDate=new Date();
      var myFutureDate=new Date(myCurrentDate);
      var expiry_figure = Number(this.packageData.package.expiry_figure)
      switch (this.packageData.package.expiry_type) {
        case "DATE":  
            switch(this.packageData.package.expiry_unit) {
                case "DAY":
                myFutureDate.setDate(myFutureDate.getDate()+ expiry_figure)
                break;
                case "MONTH":
                myFutureDate.setMonth(myFutureDate.getMonth()+ expiry_figure)
                break;
                case "YEAR":
                myFutureDate.setYear(myFutureDate.getFullYear()+ expiry_figure)
                break;
            }
            break;
        case "QUANTITY":
            let day = expiry_figure * this.now_quantity
            myFutureDate.setDate(myFutureDate.getDate()+ day)
            break;
        }
      } catch {
        var myCurrentDate=new Date();
        var myFutureDate=new Date(myCurrentDate);
      }
      return myFutureDate.getFullYear() + "-" +  (myFutureDate.getMonth()+1)  + "-" + myFutureDate.getDate()
    },

    selected_package_quantity() {
      try {
      switch (Number(this.packageData.package.lock_quantity)) {
        case 0:
          this.lock_quantity = false
          break;
        case 1:
          this.lock_quantity = true
          break;
      }
       return this.packageData.package.quantity
      } catch {
      return 0
      }
    }
  },
  watch : {
    "now_quantity" : {
        handler(newValue, oldValue) {
            if (newValue > 0) {
            this.expire_at = this.default_expiry_date
            }
        }
    },
    "card.number": {
      handler(newValue, oldValue) {
        console.log(newValue.substring(0,1))
        switch (newValue.substring(0,1)) {
          case "3":
            this.credit_card_format = '#### ###### #####'
            this.cvc_format = '####'
            break;
          default:
          this.credit_card_format = '#### #### #### ####'
            this.cvc_format = '###'
        }
      }
    },
    selected_package : {
      handler(n,o) {
        this.packageData.package = n
        this.expire_at = this.default_expiry_date
        console.log(this.default_expiry_date)
        this.now_quantity = this.packageData.package.quantity
        this.now_price = this.packageData.package.price
      //  console.log(this.packageData.package.lock_price)
          switch (Number(this.packageData.package.lock_price)) {
            case 0:
              this.lock_price = false
              break;
            case 1:
              this.lock_price = true
              break;
          }
          switch (Number(this.packageData.package.lock_quantity)) {
            case 0:
              this.lock_quantity = false
              break;
            case 1:
              this.lock_quantity = true
              break;
          }
      }
    },
  },
  data() {
    return {
    show: false,
    processing: false,
    now_quantity:0,
    now_price:0,
    expire_at:null,
    stripe_id:null,
    selected_package:[],
    credit_card_format : '#### #### #### ####',
      cvc_format : '###',
    selected_staff:null,
    commission:[],
    installment:{
      enabled:false,
      quantity:2
    },
    card : {
      number:null,
      expire:null,
      cvc:null,
      amount:0,
      member_id:this.userData.member_id
    },
      payment_method : [],
      iframe_src : '//www.cloudfit.pro/invoice.php',
      required,
      paymentMethodOptions: [
        { label: 'Cash', value: 'cash' },
        { label: 'Visa', value: 'visa' },
      ],
      staffs:[],
      lock_quantity : true,
      lock_price : true,
      percentage:null,
      alphaNum,
      email,
      countries,
      blankPackageData: {
        expire_at: '',
      },
      return_installment: null,
      packageData:{
        center_id:0,
        remarks:null,
        package:{
          price:0.00,
          quantity:0,
          payment_method:'',
          expiry_figure:'1',
          expiry_unit:'DAY'
        }
      }
    }
  },
  created() {
    this.get_staffs()
  },
  methods: {
    clear_commission() {
      this.commission = []
    },
    add_commission() {
      if (parseInt(this.percentage) <= 100 && parseInt(this.percentage) > 0 ) {
        let exist_total_percentage = parseInt(0)
        this.commission.forEach((item, i) => {
          exist_total_percentage += parseInt(item.percentage)
        });
        if ( (exist_total_percentage + parseInt(this.percentage)) > 100) {
          return
        }
        let result = {
          user : this.selected_staff,
          percentage : this.percentage
        }
        this.commission.push(result)
        this.percentage = null
      }
    },
    get_staffs() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/staffs/select/?mode=all')
        .then(res => {
          this.staffs = res.data
        })
    },
    async print () {
      // Pass the element id here
      await this.$htmlToPaper('printMe');
    },
    async add_payment() {
      if (this.packageData.payment_amount > this.selected_package_price ) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: '金額大於選擇項目',
          },
        })
        return
      }
      switch(this.packageData.payment_method) {
        case "STRIPE":
        this.show = true
        this.card.amount = this.packageData.payment_amount
        this.card.member_id = this.userData.member_id
        if (this.installment.enabled === true) {
          if (this.packageData.package.has_commission == 'Y' && this.commission.length < 1 ) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: "請先輸入佣金比例",
              },
            })
            this.show = false
            return
          }
          this.card.commission = this.commission
          this.card.installment = this.installment
          this.card.installment.amount = this.installment_amount
        }

        await this.$http.post(process.env.VUE_APP_API_BASE+'/payment_gateway/stripe/' + this.$store.getters['center/currentCenterId'] + '/', this.card)
          .then(res => {
            if (res.data.result === true) {
              this.$toast({
              component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
              this.stripe_id = res.data.stripe_id
              if (res.data.installment.enabled === true) {
                this.return_installment = res.data.installment
              }
              this.card.number = null
              this.card.expire = null
              this.card.cvc = null
              this.card.amount = 0
              this.show = false
              this.add_payment_2()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-center',
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: res.data.message,
                },
              },
              {
                timeout:999999,
              })
              this.show = false
            }
          })
        break;
        default:
          this.add_payment_2()
        break;

      }


    },
    clear_payment() {
      this.payment_method = [];
      this.packageData.payment_amount = 0
    },
    add_payment_2() {
      this.payment_method.push({
        "method" : this.packageData.payment_method,
        "amount" : this.packageData.payment_amount,
        "installment" : this.return_installment,
        "stripe_id" : this.stripe_id
      })
      this.stripe_id = null
      this.return_installment = null
      this.packageData.payment_amount = 0
      this.packageData.payment_method = null
      console.log(this.payment_method)
    },
    submit_now() {
      this.processing = true
      if ( this.commission.length > 0 ) {
        this.packageData.commission = this.commission
      }
      if (this.total < this.packageData.price) {}
      this.packageData.center_id = this.$store.getters['center/currentCenterId']
      this.packageData.expire_at = this.expire_at
      this.packageData.payment = this.payment_method
      this.packageData.package.quantity = this.now_quantity
      this.packageData.package.price = this.now_price

          this.$http.post(process.env.VUE_APP_API_BASE+'/member/'+this.userData.member_id+'/package/add/', this.packageData)
            .then(res => {
              if (res.data.result === true) {

                this.packageData = this.blankPackageData
                this.selected_package = []
                this.selected_package_price = 0
                this.selected_package_quantity = 0
                this.$refs.simpleRules.reset()
                this.processing = false
                if (res.data.status == 'ACTIVE') {
                  this.$emit('clicked', false)
                  if (res.data.is_pdf == true) {
                    this.iframe_src = '//www.cloudfit.pro/pdf/INVOICE_'+res.data.transaction_code+'.pdf'
                  } else {
                    this.iframe_src = '//www.cloudfit.pro/invoice.php?print=n&code='+res.data.transaction_id
                  }
                  this.$refs['my-modal'].show()
                }
                if (res.data.status == 'PENDING') {
                  router.push('/epad/'+res.data.member_package_id+'/').catch(() => {})
             //     this.$refs['my-modal'].show()
                }
              } else {
                this.processing = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })

    },
  },
  setup(props, { emit }) {
    const blankPackageData = {
      expire_at:'',
      remakrs:'',
      quantity:'',
      package: {
        expiry_figure:'1',
        expiry_unit:'DAY'
      }
    }

    const packageData = ref(JSON.parse(JSON.stringify(blankPackageData)))
    const resetuserData = () => {
      packageData.value = JSON.parse(JSON.stringify(blankPackageData))
    }

    const onSubmit = () => {

    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetuserData)

    return {
      packageData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
