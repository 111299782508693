<template>
  <b-sidebar
    id="add-snapshot-sidebar"
    :visible="isSnapShotSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="$emit('clicked',false);"
    @change="(val) => $emit('one')"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          SnapShot
        </h5>
      </div>
      <div class=" align-items-center  px-2 py-1">
        <v-easy-camera
            v-model="picture"
            ref="cam"
            :startOnMounted="startOnMounted"
            :autoDetectMobile="startOnMounted"
            class="mb-5"
          />

          <b-button
            variant="primary"
            @click="capture()"
            size="lg"
            style="padding:4rem 2rem"
            block
          >
          Capture
        </b-button>
        <hr class="my-5"/>
        <b-button
          variant="outline-primary"
          @click="switch_cam()"
          block
           class="my-2"
        >
        Switch Camera
      </b-button>
        <b-button
          variant="warning"
          @click="start()"
          block
        >
        Start Camera
      </b-button>
      <b-button
        variant="danger"
        @click="upload()"
        block
        v-if="picture != null">
      Upload
    </b-button>
      <!--<img :src="picture" style="max-width:100%"> -->
    </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BModal, VBModal
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import countries from '@/@fake-db/data/other/countries'
import router from '@/router'
import EasyCamera from 'easy-vue-camera'
export default {
  components: {
    ToastificationContent,
    flatPickr,
    BSidebar,
    BForm,
    BFormGroup,
     'v-easy-camera': EasyCamera,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BModal, VBModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: 'isAddNewMemberSidebarActive',
    event: 'update:is-add-new-Member-sidebar-active',
  },
  props: {
    isSnapShotSidebarActive: {
      type: Boolean,
      required: true,
    },
    userType: {
      type : String,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  computed: {

  },
  data() {
    return {
      capturing: false,
      picture:null,
      startOnMounted:false,
    }
  },
  created() {
  },
  methods: {
    capture() {
      this.$refs.cam.snap()
    },
    switch_cam() {
      this.$refs.cam.switchCamera()
    },
    start() {
      this.$refs.cam.start()
      this.$refs.cam.start()
    },
    upload() {
      let data = {
        type:this.userType,
        base64: this.picture,
        token: this.userData.token
      }
      this.$http.post(process.env.VUE_APP_API_BASE+'/member/'+this.userData.member_id+'/snapshot/', data)
        .then(res => {
          if (res.data.result === true) {
            this.$emit('clicked', false)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
